import { useState, useEffect } from "react";
// react-router components
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Icon from "@mui/material/Icon";
// Balearen Whisper React components
//import MDBox from "components/MDBox";
//import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import moment from 'moment';
//import MDBadge from "components/MDBadge";
import CountryCity from "../../../assets/countriescities.json";

// Images
//import team2 from "assets/images/team-2.jpg";
//import team3 from "assets/images/team-3.jpg";
//import team4 from "assets/images/team-4.jpg";

export default function Data() {
  let navigate = useNavigate();
  const [ tableLoader , setTableLoader]= useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});
  const [assetCategoryName, setAssetCategoryName] = useState(''); 
  const [assetLocationName, setAssetLocationName] = useState(''); 
  const [assetSaleType, setAssetSaleType] = useState(''); 
  const [userType, setUserType] = useState('');

  
  const handleAssetCategoryNameChange = (selectedCategoryName) => {
    setAssetCategoryName(selectedCategoryName);
  };
  const handleAssetLocationNameChange = (selectedLocationName) => {
    setAssetLocationName(selectedLocationName);
  };
  const handleAssetSaleTypeChange = (selectedSaleType) => {
    setAssetSaleType(selectedSaleType);
  };
  const handleUserTypeChange = (selectedUserType) => {
    setUserType(selectedUserType);
  };
  const LoadData = async() => {
    try {
      var userparam={
        assetCategoryName,
        assetLocationName,
        assetSaleType,
        userType
      };
      setTableLoader(true);
      await axios.post("/api/admin/assets",userparam).then((res) => {
        var response = res.data;
        console.log("responseeeeeeeeeee",response);
        response = response.assetsList;
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          //console.log(assetImage);
          var inc=i+1;
          var sublocation = "";
          if(response[i].subLocation != "")
          {
            sublocation = "("+response[i].subLocation+")";
          }
          responseData.push(
            {
              id: inc,
              //assetPic: <MDAvatar src={response[i].assetPic} />,
              assetPic: <MDAvatar src={response[i].signedUrl}/>,
              assetName: response[i].assetName,
              userType: response[i].userId.userType,
              //assetUserName: response[i].userId.firstName + " " + response[i].userId.lastName,
              //assetUserEmail: response[i].userId.email,
              category: response[i].assetCategory,
              //subcategoy: response[i].subCategory,
              
              location: response[i].location+sublocation,
              sellingPrice: response[i].sellingPrice,
              saleType: response[i].saleType,
              date: formatUTCDate(response[i].createdAt),
              //furtherInfo: response[i].furtherInfo,
              furtherInfo: <Link to={"/view-asset/"+response[i]._id} state={{id:response[i]._id}}>View</Link>,
              action: <Link to={"/edit-asset/"+response[i]._id} state={{id:response[i]._id}}>Edit</Link>,
              status: Dropdown(navigate, response[i]._id, response[i].isDelete, status, setStatus, LoadData),
            }
          );
        }
        setTableLoader(false);
        setData(responseData);
      });
    } catch (error) {
        console.log(error);
    }
  };
  useEffect(() => {
    LoadData();
  },[]); 

  

  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "assetPic", accessor: "assetPic" },
      { Header: "assetName", accessor: "assetName" },
      { Header: "userType", accessor: "userType" },
      //{ Header: "assetUserName", accessor: "assetUserName" },
      //{ Header: "assetUserEmail", accessor: "assetUserEmail" },
      { Header: "category", accessor: "category" },
      //{ Header: "subcategoy", accessor: "subcategoy" },
      { Header: "location", accessor: "location" },
      { Header: "sellingPrice", accessor: "sellingPrice" },
      { Header: "date", accessor: "date" },
      { Header: "saleType", accessor: "saleType" },
      { Header: "furtherInfo", accessor: "furtherInfo" },
      { Header: "action", accessor: "action" },
      { Header: "status", accessor: "status" },
    ],

    rows: data ? data : "",
    assetcategoryoption: (
      <AssetCategoryNameDropDown
      assetCategoryName={assetCategoryName}
      handleAssetCategoryNameChange={handleAssetCategoryNameChange}
        LoadData={()=>LoadData()}
      />
    ),
    assetlocationoption: (
      <AssetLocationNameDropDown
      assetLocationName={assetLocationName}
      handleAssetLocationNameChange={handleAssetLocationNameChange}
        LoadData={()=>LoadData()}
      />
    ),

    assetsaletypenoption: (
      <AssetSaleTypeDropDown
      assetSaleType={assetSaleType}
      handleAssetSaleTypeChange={handleAssetSaleTypeChange}
        LoadData={()=>LoadData()}
      />
    ),

    usertypeoption: (
      <UserTypeDropDown
        userType={userType}
        handleUserTypeChange={handleUserTypeChange}
        LoadData={()=>LoadData()}
      />
    ),
    tableLoader
  };
}

function Dropdown(navigate, assetId, assetStatus, status, setStatus, LoadData) {
  //const [status, setStatus] = useState(assetStatus);
  //setStatus({...status, assetId: assetStatus});
  //console.log(status);
  const changeStatus = async (e) => {
    var isDelete=e.target.value;
    var data={
      assetId,
      isDelete,
    };
    try {
      axios.post("/api/admin/assetStatus", data).then((res) => {
        var response = res.data;
        if(response.status===1){
        //setStatus(response.isDelete);
        swal("success", "Status has been changed successfully!", "success");
        LoadData();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteAsset = async (e) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this asset!",
      icon: "warning",
      buttons: [
        'No',
        'Yes'
      ],
      dangerMode: true,
    }).then(function(isConfirm) {
      if (isConfirm) {
        var id=e.target.attributes.value.nodeValue;
        swal("success", "Asset deleted successfully!", "success");
		    navigate("/assets");
      }
    });
  };
  return (
    <span>
      <select className="form-control selectbutton" value={assetStatus} onChange={changeStatus}>
        <option value="0">Restore</option>
        <option value="1">Delete</option>
      </select>
	  {/* <a href="javascript:void(0);" onClick={deleteAsset}><Icon fontSize="small" value={assetId}>delete</Icon></a> */}
      {/* <Link to={"/assets/"+assetId}>View</Link> */}
      {/* <Link to={"/view-asset"} state={{id:assetId}}>View</Link> */}
    </span>
  );
}
function formatUTCDate(utcDate) {
  // Parse the UTC date string into a moment object
  const momentDate = moment(utcDate);

  // Format the date with AM/PM format
  const formattedDate = momentDate.format('MM/DD/YYYY, hh:mm A');

  return formattedDate;
}

// AssetCategoryNameDropDown component
function AssetCategoryNameDropDown({ assetCategoryName, handleAssetCategoryNameChange, LoadData}) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    //console.log('usertype', userType); // This will log the updated userType value
    async function fetchCategories() {
      try {
        const response = await axios.post("/api/getAssetCategories");
        console.log(response);
        if (response.data.status === 1) {
          setCategories(response.data.categoryList);
        }
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    }

    fetchCategories();
    LoadData();
    
    
  }, [assetCategoryName,]);

  return (
    <>
    <select
      name="selectUserType"
      className="form-control selectbutton"
      onChange={(e) => handleAssetCategoryNameChange(e.target.value)}
      value={assetCategoryName}>
        
      <option value="">All Category</option>
      {categories.map((category) => (
        <option key={category._id} value={category.categoryName}>
          {category.categoryName}
        </option>
      ))}
      
    </select>
    </>

  );
}

// AssetLocationNameDropDown component
function AssetLocationNameDropDown({ assetLocationName, handleAssetLocationNameChange, LoadData}) {
  
  useEffect(() => {
    
    LoadData();
    
    
  }, [assetLocationName]);

  return (
    <>
    <select
      name="selectUserType"
      className="form-control selectbutton"
      onChange={(e) => handleAssetLocationNameChange(e.target.value)}
      value={assetLocationName}>
        
      <option value="">All Locations</option>
      {CountryCity.map((location) => (
        <option key={location.name} value={location.name}>
          {location.name}
        </option>
      ))}
      
    </select>
    </>

  );
}

// AssetSaleTypeDropDown component
function AssetSaleTypeDropDown({ assetSaleType, handleAssetSaleTypeChange, LoadData}) {
  const [saleTypes, setSaleTypes] = useState([]);
  useEffect(() => {
    //console.log('usertype', userType); // This will log the updated userType value
    async function fetchSaleTypes() {
      try {
        const response = await axios.post("/api/getSaleTypes");
        console.log(response);
        if (response.data.status === 1) {
          setSaleTypes(response.data.saleTypeList);
        }
      } catch (error) {
        console.error("Error fetching saletypes: ", error);
      }
    }

    fetchSaleTypes();
    LoadData();
    
    
  }, [assetSaleType]);

  return (
    <>
    <select
      name="selectUserType"
      className="form-control selectbutton"
      onChange={(e) => handleAssetSaleTypeChange(e.target.value)}
      value={assetSaleType}>
        
      <option value="">All Sale Type</option>
      {saleTypes.map((sales) => (
        <option key={sales._id} value={sales.saleType}>
          {sales.saleType}
        </option>
      ))}
      
    </select>
    </>

  );
}
// UserTypeDropDown component
function UserTypeDropDown({ userType, handleUserTypeChange, LoadData}) {
  
  useEffect(() => {
    //console.log('usertype', userType); // This will log the updated userType value
    LoadData();
    
    
  }, [userType]);

  return (
    <>
    <select
      name="selectUserType"
      className="form-control selectbutton"
      onChange={(e) => handleUserTypeChange(e.target.value)}
      value={userType}>
        
      <option value="">All Users</option>
      <option value="Seller">Seller</option>
      <option value="Investor">Investor</option>
      
    </select>
    </>

  );
}


