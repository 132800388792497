import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Editor from "components/Custom/Editor";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

export default function EditContent() {
    let { id } = useParams();
    const [data, setData] = useState(false);
    useEffect(() => {
        if(id){
            axios.post("/api/admin/getContentById", {id}).then((res) => {
                const response = res.data;
                setData(response.contentsList);
            });
        }
    }, [id]);       
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                            View Content
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <div className="formContainer">
								<div class="form-group">
									<label for="email">{data?.title}</label>
								</div>
								<hr/>
								<div class="form-group">
									<div dangerouslySetInnerHTML={{ __html: data?.content }} />
								</div> 
                            </div>
                        </MDBox>
                    </Card>
                </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
