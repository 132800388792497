import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import swal from "sweetalert";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

function Basic() {
  let navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    let details = {
      email: email.value,
      password: password.value,
    };
    axios.post("/api/admin/login", details).then((res) => {
      const response = res.data;
      if (response.status == 0) {
        setErrors(response.errors);
      } else {
        localStorage.setItem("authUser", JSON.stringify(response.data));
        swal("success", "Login Successfully!", "success");
        navigate("/dashboard");
      }
    });
  };

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            <img src={brandWhite} style={{width: 70}}/>
            <div>Sign in</div>
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleLogin.bind(this)}>
            <MDBox mb={2}>
              <MDInput name="email" type="email" label="Email" fullWidth />
              <span className="redError">{errors.email ? errors.email : ""}</span>
            </MDBox>
            <MDBox mb={2}>
              <MDInput name="password" type="password" label="Password" fullWidth />
              <span className="redError">{errors.password ? errors.password : ""}</span>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
