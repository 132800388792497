import React, { useState, useEffect } from 'react';
import {
  Link, Router,
} from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import CircularProgress from '@mui/material/CircularProgress';
import CircularProgress from "../../assets/images/giphy.gif";
// Data
import authorsTableData from "layouts/new_assets/data/authorsTableData";
// import Loader from "../../assets/images/giphy.gif"

function Tables() {
  const { columns, rows,tableLoader} = authorsTableData();

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white" className="contentList">
                  New Assets List
                </MDTypography>
				        
              </MDBox>
              <MDBox pt={3}>
              
               <div className='tableloder'>
                
               {
                tableLoader ? (
                  
                  <img className='loa_der' src={CircularProgress}/>
                
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    //isSorted={false}
                    canSearch={true}
                    entriesPerPage={false}
                    pagination={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
                
                </div>

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
