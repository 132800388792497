import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Editor from "components/Custom/Editor";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

export default function AddFaq() {
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [content, setContent] = useState("");
    let navigate = useNavigate();
    const [errors, setErrors] = useState(false);
    useEffect(() => {
        setEditorLoaded(true);
    }, []);
    const submitData = (e) => {
        e.preventDefault();
        var data = {
            question: e.target.faq_question.value,
            answer: e.target.faq_answer.value,
            
        };
        axios.post("/api/admin/addFaqs", data).then((res) => {
            const response = res.data;
            if (response.status == 0) {
                setErrors(response.errors);
            } else {
                swal("success", "Faq added successfully!", "success");
                navigate("/faqs");
            }
        });
    };        
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                            Add Faq
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <div className="formContainer">
                                <form onSubmit={submitData}>
                                    <div class="form-group">
                                        <label for="faq_question" className="titlhed">Question:</label>
                                        <input type="text" class="form-control" id= "faq_question" name="faq_question"/>
                                        <span className="redError">{errors.question ? errors.question : ""}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="faq_answer" className="titlhed">Answer:</label>
                                        <textarea class="form-control" id="faq_answer" name="faq_answer" rows="4" cols="50">
                                        
                                        </textarea>
                                        <span className="redError">{errors.answer ? errors.answer : ""}</span>
                                    </div>
                                    
                                    <button type="submit" class="btn btn-primary float-end mt-2 mb-2">Add</button>
                                </form> 
                            </div>
                        </MDBox>
                    </Card>
                </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
