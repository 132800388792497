import { useState, useEffect } from "react";
// react-router components
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Icon from "@mui/material/Icon";
// Balearen Whisper React components
//import MDBox from "components/MDBox";
//import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import moment from 'moment';
//import MDBadge from "components/MDBadge";
import CountryCity from "../../../assets/countriescities.json";

// Images
//import team2 from "assets/images/team-2.jpg";
//import team3 from "assets/images/team-3.jpg";
//import team4 from "assets/images/team-4.jpg";

export default function Data() {
  let navigate = useNavigate();
  const [ tableLoader , setTableLoader]= useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});

  
  
  const LoadData = async() => {
    try {
      
      setTableLoader(true);
      await axios.post("/api/admin/newassets").then((res) => {
        var response = res.data;
        
        response = response.assetsList;
        var responseData = [];
        for (var i = 0; i < response.length; i++) {
          //console.log(assetImage);
          var inc=i+1;
          var sublocation = "";
          if(response[i].subLocation != "")
          {
            sublocation = "("+response[i].subLocation+")";
          }
          responseData.push(
            {
              id: inc,
              //assetPic: <MDAvatar src={response[i].assetPic} />,
              assetPic: <MDAvatar src={response[i].signedUrl}/>,
              assetName: response[i].assetName,
              userType: response[i].userId.userType,
              //assetUserName: response[i].userId.firstName + " " + response[i].userId.lastName,
              //assetUserEmail: response[i].userId.email,
              category: response[i].assetCategory,
              //subcategoy: response[i].subCategory,
              
              location: response[i].location+sublocation,
              sellingPrice: response[i].sellingPrice,
              saleType: response[i].saleType,
              date: formatUTCDate(response[i].createdAt),
              //furtherInfo: response[i].furtherInfo,
              furtherInfo: <Link to={"/view-asset/"+response[i]._id} state={{id:response[i]._id}}>View</Link>,
              action: <Link to={"/edit-asset/"+response[i]._id} state={{id:response[i]._id}}>Edit</Link>,
              status: Dropdown(navigate, response[i]._id, response[i].isAdminApprove, status, setStatus, LoadData),
            }
          );
        }
        setTableLoader(false);
        setData(responseData);
      });
    } catch (error) {
        console.log(error);
    }
  };
  useEffect(() => {
    LoadData();
  },[]); 

  

  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "assetPic", accessor: "assetPic" },
      { Header: "assetName", accessor: "assetName" },
      { Header: "userType", accessor: "userType" },
      //{ Header: "assetUserName", accessor: "assetUserName" },
      //{ Header: "assetUserEmail", accessor: "assetUserEmail" },
      { Header: "category", accessor: "category" },
      //{ Header: "subcategoy", accessor: "subcategoy" },
      { Header: "location", accessor: "location" },
      { Header: "sellingPrice", accessor: "sellingPrice" },
      { Header: "date", accessor: "date" },
      { Header: "saleType", accessor: "saleType" },
      { Header: "furtherInfo", accessor: "furtherInfo" },
      { Header: "action", accessor: "action" },
      
      { Header: "status", accessor: "status" },
    ],

    rows: data ? data : "",
    
    tableLoader
  };
}

function Dropdown(navigate, assetId, assetStatus, status, setStatus, LoadData) {
  //const [status, setStatus] = useState(assetStatus);
  //setStatus({...status, assetId: assetStatus});
  //console.log(status);
  const changeStatus = async (e) => {
    var isAdminApprove=e.target.value;
    var data={
      assetId,
      isAdminApprove,
    };
    try {
      axios.post("/api/admin/assetAdminApprove", data).then((res) => {
        var response = res.data;
        if(response.status===1){
        //setStatus(response.isAdminApprove);
        swal("success", "Status has been changed successfully!", "success");
        navigate("/assets");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <span>
      <select className="form-control selectbutton" value={assetStatus} onChange={changeStatus}>
        <option value="0">Action</option>
        <option value="1">Approve</option>
        <option value="2">Disapprove</option>
      </select>
	  
    </span>
  );
}
function formatUTCDate(utcDate) {
  // Parse the UTC date string into a moment object
  const momentDate = moment(utcDate);

  // Format the date with AM/PM format
  const formattedDate = momentDate.format('MM/DD/YYYY, hh:mm A');

  return formattedDate;
}




