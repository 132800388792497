import { useState, useEffect, useMemo } from "react";
import { FaEdit, FaTrash, FaEye } from 'react-icons/fa';
import axios from "axios";
import swal from "sweetalert";
// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";



export default function Data() {
  
  
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});
  const [userType, setUserType] = useState(''); // Initialize userType state with an empty string

  
  const handleUserTypeChange = (selectedUserType) => {
    setUserType(selectedUserType);
  };
  const LoadData = () => {
    
    
    var userparam={
      userType,
    };
    
    
    //console.log('usertype',userType);
    axios.post("/api/admin/users",userparam).then((res) => {
      var response = res.data;
      response = response.usersList;
      var responseData = [];
      for (var i = 0; i < response.length; i++) {
        var inc=i+1;
        responseData.push(
          {
            id: inc,
            userPic: <MDAvatar src={response[i].profilePic}/>,
            userType: response[i].userType,
            name: response[i].firstName + " " + response[i].lastName,
            email: response[i].email,
            company: response[i].company,
            phone: response[i].phone,
            address: response[i].address,
            // <Link to={"/edit-asset/"+response[i]._id} state={{id:response[i]._id}}><FaEye title="View"/></Link>
            // action: <> <Link to={"/edit-user/"+response[i]._id} state={{id:response[i]._id}}><FaEdit title="Edit"/></Link> <Link to={"/delete-user/"+response[i]._id} state={{id:response[i]._id}}><FaTrash title="Delete"/></Link>
            action: <> <Link to={"/edit-user/"+response[i]._id} state={{id:response[i]._id}}><FaEdit title="Edit"/></Link>
            </>,

          
            status: Dropdown(response[i]._id, response[i].isDelete, status, setStatus, LoadData),
          }
        );
      }
      setData(responseData);
    });
    
  };

  useEffect(() => {
    LoadData();
  }, [userType]);

  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "userPic", accessor: "userPic" },
      { Header: "userType", accessor: "userType" },
      { Header: "name", accessor: "name" },
      { Header: "email", accessor: "email" },
      { Header: "company", accessor: "company" },
      { Header: "phone", accessor: "phone" },
      { Header: "address", accessor: "address" },
      { Header: "action", accessor: "action" },
      { Header: "status", accessor: "status" },
    ],

    rows: data ? data : "",
    usertypeoption: (
      <UserTypeDropDown
        userType={userType}
        handleUserTypeChange={handleUserTypeChange}
        LoadData={LoadData}
      />
    ),
  };
}



function Dropdown(userId, userStatus, status, setStatus, LoadData) {
  //const [status, setStatus] = useState(userStatus);
  //setStatus({...status, userId: userStatus});
  //console.log(status);
  const deleteUser = async (e) => {
    var isDelete=e.target.value;
    var data={
      userId,
      isDelete,
    };
    axios.post("/api/admin/userStatus", data).then((res) => {
      var response = res.data;
      if(response.status==1){
        //setStatus(response.isDelete);
        swal("success", "Status has been changed successfully!", "success");
        LoadData();
      }
    });
  };
  return (
    <select name="selectUser" className="form-control selectbutton" value={userStatus} onChange={deleteUser}>
       {userStatus === "0" ? (
      <><option value="1">Disabled</option>
      <option value="0">Enabled</option>
       
      </>
    ) : (
      <>
         <option value="0">Enabled</option>
        <option value="1">Disabled</option>
      </>
    )}
     
      
    </select>
  );
}

// UserTypeDropDown component
function UserTypeDropDown({ userType, handleUserTypeChange, LoadData}) {
  
  useEffect(() => {
    //console.log('usertype', userType); // This will log the updated userType value
    LoadData();
    
    
  }, [userType, LoadData]);

  return (
    <>
    <select
      name="selectUserType"
      className="form-control selectbutton"
      onChange={(e) => handleUserTypeChange(e.target.value)}
      value={userType}>
        
      <option value="">All</option>
      <option value="Seller">Seller</option>
      <option value="Investor">Investor</option>
      
    </select>
    </>

  );
}


