import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import swal from "sweetalert";
// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function Data() {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});

  const LoadData = () => {
    axios.post("/api/admin/newUsers").then((res) => {
      var response = res.data;
      response = response.usersList;
      var responseData = [];
      for (var i = 0; i < response.length; i++) {
        var inc=i+1;
        responseData.push(
          {
            id: inc,
            userPic: <MDAvatar src={response[i].profilePic}/>,
            userType: response[i].userType,
            name: response[i].firstName + " " + response[i].lastName,
            email: response[i].email,
            company: response[i].company,
            phone: response[i].phone,
            address: response[i].address,
            action: Dropdown(response[i]._id, response[i].activeByAdmin, status, setStatus, LoadData),
          }
        );
      }
      setData(responseData);
    });
  };

  useEffect(() => {
    LoadData();
  }, []);

  return {
    columns: [
      { Header: "id", accessor: "id" },
      { Header: "userPic", accessor: "userPic" },
      { Header: "userType", accessor: "userType" },
      { Header: "name", accessor: "name" },
      { Header: "email", accessor: "email" },
      { Header: "company", accessor: "company" },
      { Header: "phone", accessor: "phone" },
      { Header: "address", accessor: "address" },
      { Header: "action", accessor: "action" },
    ],

    rows: data ? data : "",
  };
}

function Dropdown(userId, userStatus, status, setStatus, LoadData) {
  //const [status, setStatus] = useState(userStatus);
  //setStatus({...status, userId: userStatus});
  //console.log(status);
  const newUserStatus = async (e) => {
    var activeByAdmin=e.target.value;
    var data={
      userId,
      activeByAdmin,
    };
    axios.post("/api/admin/newUserStatus", data).then((res) => {
      var response = res.data;
      if(response.status==1){
        //setStatus(response.isDelete);
        swal("success", "Status has been changed successfully!", "success");
        LoadData();
      }
    });
  };
  return (
    <select name="selectUser" className="form-control selectbutton" value={userStatus} onChange={newUserStatus}>
      <option value="0">Select</option>
      <option value="1">Approve</option>
      <option value="2">Disaprove</option>
    </select>
  );
}
