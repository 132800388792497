import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

import Icon from "@mui/material/Icon";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import {format} from 'date-fns';
function removeTags(str) {
  if ((str===null) || (str===''))
      return false;
  else
      str = str.toString();
        
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace( /(<([^>]+)>)/ig, '');
}

export default function Data() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios.post("/api/admin/contents").then((res) => {
      var response = res.data;
      response = response.contentsList;
      var responseData = [];
      for (var i = 0; i < response.length; i++) {
        var inc=i+1;
        var contentAll = removeTags(response[i].content);
        var contentShort = contentAll.substring(0,100);
        responseData.push(
          {
            date: format(new Date(response[i].updatedAt), 'dd/MM/yyyy, p'),
            title: response[i].title,
            content: contentShort+'...',
            action: Action(navigate, response[i]._id),
          }
        );
      }
      setData(responseData);
    });
  }, []);

  return {
    columns: [
      { Header: "Recently updated", accessor: "date" },
      { Header: "title", accessor: "title" },
      { Header: "content", accessor: "content" },
      { Header: "action", accessor: "action" },
    ],

    rows: data ? data : "",
  };
}

function Action(navigate, contentId) {
  //const [status, setStatus] = useState(userStatus);
  const editContent = async (e) => {
    var id=e.target.attributes.value.nodeValue;
    navigate("/editContent/"+id);
  };
  const viewContent = async (e) => {
    var id=e.target.attributes.value.nodeValue;
    navigate("/viewContent/"+id);
  };
  const deleteContent = async (e) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this content!",
      icon: "warning",
      buttons: [
        'No',
        'Yes'
      ],
      dangerMode: true,
    }).then(function(isConfirm) {
      if (isConfirm) {
        var id=e.target.attributes.value.nodeValue;
        axios.post("/api/admin/deleteContents", {id}).then((res) => {
          var response = res.data;
          if(response.status==1){
            swal("success", "Content deleted successfully!", "success");
            navigate("/contents");
          }
        });
      }
    });
  };
  
  return (
    <>
      <span className="formAction">
		<a href="javascript:void(0);" onClick={viewContent}><Icon fontSize="small" value={contentId}>visibility</Icon></a>
        <a href="javascript:void(0);" onClick={editContent}><Icon fontSize="small" value={contentId}>edit</Icon></a>
        {/* |<a href="javascript:void(0);" onClick={deleteContent}><Icon fontSize="small" value={contentId}>delete</Icon></a> */}
      </span>
    </>
  );
}
