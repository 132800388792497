// Balearen Whisper React layouts
import Dashboard from "layouts/dashboard";
import Assets from "layouts/assets";
import NewAssets from "layouts/new_assets";
import AddAsset from "layouts/assets/AddAsset";
import EditAsset from "layouts/assets/EditAsset";
import ViewAsset from "layouts/assets/ViewAsset";
import Interests from "layouts/interests";
import AddInterest from "layouts/interests/AddInterest";
import EditInterest from "layouts/interests/EditInterest";
import ViewInterest from "layouts/interests/ViewInterest";

import NewUsers from "layouts/new_users";
import Users from "layouts/users";
import EditUser from "layouts/users/EditUser";
import DeleteUser from "layouts/users/DeleteUser";
import Contents from "layouts/contents";
import AddContent from "layouts/contents/AddContent";
import EditContent from "layouts/contents/EditContent";
import ViewContent from "layouts/contents/ViewContent";

import Faqs from "layouts/faqs";
import AddFaqs from "layouts/faqs/AddFaqs";
import EditFaq from "layouts/faqs/EditFaq";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
//import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Assets",
    key: "assets",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/assets",
    component: <Assets />,
  },
  {
    type: "collapse",
    name: "New Assets",
    key: "new_assets",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/new_assets",
    component: <NewAssets />,
  },
  {
    type: "route",
    name: "Add Asset",
    key: "addAsset",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/addAsset",
    component: <AddAsset />,
  },
  {
    type: "route",
    name: "Edit Asset",
    key: "edit-asset",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/edit-asset/:id",
    component: <EditAsset />,
  },
  {
    type: "route",
    name: "View Asset",
    key: "view-asset",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/view-asset/:id",
    component: <ViewAsset />,
  },
  {
    type: "collapse",
    name: "Investment Details",
    key: "interests",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/interests",
    component: <Interests />,
  },
  {
    type: "route",
    name: "Add Interest",
    key: "addInterest",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/addInterest",
    component: <AddInterest />,
  },
  {
    type: "route",
    name: "Edit Interest",
    key: "edit-interest",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/edit-interest/:id",
    component: <EditInterest />,
  },
  {
    type: "route",
    name: "View Interest",
    key: "view-interest",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/view-interest/:id",
    component: <ViewInterest />,
  },
  {
    type: "collapse",
    name: "New Users",
    key: "new_users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/new_users",
    component: <NewUsers />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "route",
    name: "Edit User",
    key: "edit-user",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/edit-user/:id",
    component: <EditUser />,
  },
  {
    type: "route",
    name: "Delete User",
    key: "delete-user",
    icon: <Icon fontSize="small">webasset</Icon>,
    route: "/delete-user/:id",
    component: <DeleteUser />,
  },
  {
    type: "collapse",
    name: "Contents",
    key: "contents",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/contents",
    component: <Contents />,
  },
  {
    type: "route",
    name: "Add Content",
    key: "add-content",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/contents/add",
    component: <AddContent />,
  },
  {
    type: "route",
    name: "Edit Content",
    key: "edit-content",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/editContent/:id",
    component: <EditContent />,
  },
  {
    type: "route",
    name: "View Content",
    key: "view-content",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/viewContent/:id",
    component: <ViewContent />,
  },
  {
    type: "collapse",
    name: "FAQs",
    key: "faqs",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/faqs",
    component: <Faqs />,
  },
  {
    type: "route",
    name: "Add Faq",
    key: "add-faq",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/faqs/add",
    component: <AddFaqs />,
  },
  {
    type: "route",
    name: "Edit Faq",
    key: "edit-faq",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/faqs/:id",
    component: <EditFaq/>,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
