import { useState, useEffect, useMemo } from "react";
import axios from "axios";

import swal from "sweetalert";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Balearen Whisper React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Balearen Whisper React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Editor from "components/Custom/Editor";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import CountryCity from "../../assets/countriescities.json";
//import CircularProgress from '@mui/material/CircularProgress';
import CircularProgress from "../../assets/images/giphy.gif";

export default function EditAsset() {
    let { id } = useParams();
    const [ tableLoader , setTableLoader]= useState(false);
    let navigate = useNavigate();
    const [errors, setErrors] = useState(false);
    
	const [users, setUsers] = useState(false);
    
    const [isEffectTriggered, setIsEffectTriggered] = useState(false);
    
    
    useEffect(() => {
        if (id && !isEffectTriggered.current) {
            try {
                var singleuserdata = {
                    userId: id,
                    type: "single",
                };
                axios.post("/api/admin/singleUser", singleuserdata).then((res) => {
                    const response = res.data;
                    if (response.status === 1) {
                        setUsers(response.users);
                    }
                });
                isEffectTriggered.current = true; // Mark the effect as triggered
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
     }, [id]);
     const changeField = (e) => {
        setUsers({...users, [e.target.name]: e.target.value});
    };
     
   
    
	
	
    
    
   
    
    const submitData = (e) => {
        e.preventDefault();
		var { reason} = e.target;
		const formData = new FormData();
		formData.append("userId",users?._id);
        formData.append("reason",reason?.value);
        //console.log(formData);
        setTableLoader(true);
        axios.post("/api/admin/deleteUser", formData).then((res) => {
            setTableLoader(false);
            const response = res.data;
            if (response.status == 0) {
                setErrors(response.errors);
            } else {
                swal("success", "User deleted successfully!", "success");
                navigate("/users");
               
                
             }
        });
    };        
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Delete User
                            </MDTypography>
                        </MDBox>
                        <MDBox p={3}>
                        <form onSubmit={submitData} className="AstForm">

                            <div className="AssetTopForm">
                                <div className="AssetForms">
                                 
                                        <div class="form-group">
                                            <label for="email">Email:</label>
                                            <input type="text" class="form-control" name="email" value={users?.email}/>
                                            <span className="redError"></span>
                                        </div>
                                        

                                        <div class="form-group">
                                            <label for="reason">Reason:</label>
                                            <input type="text" class="form-control" name="reason" value=""/>
                                            <span className="redError"></span>
                                        </div>

                                        
                                       
                                </div>
                                
                                
                            </div>

                            

                            <div className="AssetAddButton">
                                <button type="submit">Update</button>

                            </div>
                            {tableLoader ? (<img className='loa_der' src={CircularProgress}/>) : ""}
                            </form> 

                        </MDBox>
                    </Card>
                </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
